export const tiposUnidade = [
  {
    value: 0,
    label: 'Venda',
  },
  {
    value: 1,
    label: 'Compra',
  },
];
