import * as yup from 'yup';

const validationSchema = yup.object().shape({
  cod_loja: yup
    .number()
    .typeError('A loja é obrigatória ao informar o financeiro')
    .required('A loja é obrigatória ao informar o financeiro')
    .positive('A loja é obrigatória ao informar o financeiro')
    .integer('A loja é obrigatória ao informar o financeiro'),

  cod_finalizadora: yup
    .number()
    .typeError('A finalizadora é obrigatória ao informar o financeiro')
    .required('A finalizadora é obrigatória ao informar o financeiro')
    .positive('A finalizadora é obrigatória ao informar o financeiro')
    .integer('A finalizadora é obrigatória ao informar o financeiro'),

  num_condicao: yup
    .number()
    .typeError('O número da condição é obrigatório ao informar o financeiro')
    .required('O número da condição é obrigatório ao informar o financeiro')
    .integer('O número da condição é obrigatório ao informar o financeiro'),

  cod_condicao: yup
    .number()
    .typeError('O código da condição é obrigatório ao informar o financeiro')
    .required('O código da condição é obrigatório ao informar o financeiro')
    .positive('O código da condição é obrigatório ao informar o financeiro')
    .integer('O código da condição é obrigatório ao informar o financeiro'),

  cod_categoria: yup
    .number()
    .typeError('Categoria é obrigatória ao informar o financeiro')
    .required('Categoria é obrigatória ao informar o financeiro')
    .positive('Categoria é obrigatória ao informar o financeiro')
    .integer('Categoria é obrigatória ao informar o financeiro'),

  cod_cc: yup
    .number()
    .typeError('Código cc é obrigatório ao informar o financeiro')
    .required('Código cc é obrigatório ao informar o financeiro')
    .positive('Código cc é obrigatório ao informar o financeiro')
    .integer('Código cc é obrigatório ao informar o financeiro'),

  // per_desconto: yup
  //   .string()
  //   .typeError(
  //     'O percentual de desconto é obrigatório ao informar o financeiro',
  //   )
  //   .required(
  //     'O percentual de desconto é obrigatório ao informar o financeiro',
  //   ),
});

export default validationSchema;
