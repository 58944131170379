import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    des_perfil: yup.string().required(),
    operacao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    tipo_unidade: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    tipo_emissao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    tipo_nf: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();

export const schemaFinanceiro = yup
  .object({
    loja: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_finalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    num_condicao: yup.number().required(),
    cod_condicao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_cc: yup.object().shape({
      label: yup.string().notRequired(),
      value: yup.number().required(),
    }),
    cod_categoria: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();
