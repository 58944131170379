export const tiposEmissaoNf = [
  {
    value: 0,
    label: 'Terceiros',
  },
  {
    value: 1,
    label: 'Própria',
  },
];
