export const tiposOperacao = [
  {
    value: 0,
    label: 'Entrada',
  },
  {
    value: 1,
    label: 'Saída',
  },
];
