export const tiposNf = [
  {
    value: 0,
    label: 'Compra',
  },
  {
    value: 1,
    label: 'Venda',
  },
  {
    value: 2,
    label: 'Bonificação',
  },
  {
    value: 3,
    label: 'Devolução',
  },
  {
    value: 4,
    label: 'Transferência',
  },
  {
    value: 5,
    label: 'Transporte',
  },
  {
    value: 6,
    label: 'Outras',
  },
  {
    value: 7,
    label: 'Uso e consumo próprio',
  },
  {
    value: 8,
    label: 'Brindes',
  },

  {
    value: 9,
    label: 'Perda',
  },
  {
    value: 10,
    label: 'Transf. ICMS',
  },
  {
    value: 11,
    label: 'Recusa',
  },
  {
    value: 12,
    label: 'Remessa',
  },
  {
    value: 13,
    label: 'Complemento',
  },
  {
    value: 14,
    label: 'Ajuste',
  },
];
