import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .css-1mxwzzc {
    border-color: #f5f6f7;
    &:hover {
    }
  }

  .inputSelctLabel {
    margin-top: -9px;
  }

  .inputCondicao {
    border: red;
    input {
      background: #f4f5f7;
    }
  }

  .btnLimiteCredito {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    /* margin-bottom: 5px; */
    transition: 0.4s;
    color: #424242;
  }
  .btnLimiteCredito:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  .accordion-collapse {
    border-color: transparent;
    border: none !important;
  }
  .actButtonsCredito {
    margin-top: 31px;
    display: flex;
    flex-direction: row;

    /* justify-content: space-evenly; */
    .btn-outline-danger {
      border-color: #d4a9a9;
      color: #d94848;
    }
    button:nth-child(2) {
      border-color: #a9d4c0;
      color: #72ab90;
      margin-left: 10px;

      &:hover {
        background-color: #72ab90;
        color: #fff;
      }
    }
    button:focus {
      outline: 0;
    }
  }
  button:focus {
    outline: 0;
  }
  .btn-outline-danger:hover {
    background-color: #d94848;
    color: #fff;
  }
  .inputButton {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-top: -40px;
    align-items: center;
    justify-content: center;
  }

  .centralized {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .containerButton {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .containerButtonFlex {
    display: flex;
    padding: 1px;
  }

  .single-select.is-invalid {
    background-image: none;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    border-bottom: 2px solid #cf1919;
    &:focus {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 991px) {
    .perDesconto {
      width: 30% !important;
    }

    .containerButton {
      margin-left: 85px !important;
      width: 25% !important;
    }

    .containerButtonFlex {
      .confirm {
        min-width: 20px !important;
      }
      .cancel {
        min-width: 20px !important;
      }
    }
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;
`;

export const Label = styled.div`
  font-weight: 600;
  display: inline-block;
  margin-bottom: 2px;
  margin-top: 10px;
  margin-left: 0;
`;

export const Separator = styled.div`
  margin-top: 10px;
  padding: 0;

  p {
    color: #bdbdbd;
    width: 100%;
    text-align: start;
    border-bottom: 1px solid #ededed;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  p span {
    background: #fff;
    padding: 0 10px;
  }
`;

export const InpuTypeDate = styled.input`
  height: 40px;
  margin-top: 30px;
  background-color: #fafbfc;
  border: 2px solid;
  border-color: #dfe1e6;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 20px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0;
  /* outline: none; */
  width: 100%;
  line-height: 1.4285714285714286;
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;

export const StyledForm = styled.form`
  /* margin-top: 20px; */
  padding: 20px;
  border-radius: 5px;
  /* display: flex; */
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;
